.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  overflow: hidden;
  background-color: var(--light);
  font-family: $font-nunito;
  font-size: 15px;
  font-weight: 600;

  &-aside {
    padding: 0 14px;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    @include media('>=tablet') {
      width: var(--sidebar-width);
    }
  }

  &-main {
    padding: 0 18px 0 0 ;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media('>=tablet') {
      padding: 0 18px;
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;

    @include media('>=tablet') {
      margin-left: 15px;
    }
  }

  &-settings {
    display: flex;
    align-items: center;
    margin-top: 4px;

    @include media('>=tablet') {
      margin-left: 15px;
    }

    @include media('>=desktop') {
      margin-left: 10px; // 26px
    }

    .drop {
      margin-left: 17px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
