.upload-area {
  @include animate(background-color);
  padding: 16px;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--light);
  border: 1px dashed var(--gray-200);
  border-radius: $border-radius;
  color: var(--gray-200);
  font-family: $font-nunito;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background-color: var(--light-darken-5);
  }

  .upload-text {
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }

  .icon {
    font-size: 20px;
    margin-bottom: 12px;
    color:var(--gray-200) !important;
  }
}
