.drop {
  &-opener {
    display: flex;
    align-items: center;
    color: currentColor;
    background-color: transparent;
    border: 0;

    &:hover {
      color: var(--primary);
    }

    .icon {
      font-size: 23px;

      &-caret-down {
        margin-left: 10px;
        font-size: 6px;
      }
    }
  }

  &-settings {
    .icon {
      font-size: 28px;
    }
  }

  &-info {
    .drop-opener {
      color: var(--gray-200);

      &:hover {
        color: var(--primary);
      }

      .icon {
        font-size: 17px;
        color: currentColor;
      }
    }
  }
}
