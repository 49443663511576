.sidebar {
  @include animate(width transform);
  position: fixed;
  top: var(--header-height);
  left: 0;
  z-index: 100;
  width: var(--sidebar-width);
  height: calc(100vh - var(--header-height));
  padding: 21px 0;
  box-shadow: $box-shadow;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  transform: translateX(-100%);
  background-color: var(--light);

  @include media(">=tablet") {
    transform: translateX(0);
  }

  .sidebar-active & {
    transform: translateX(0);


    @include media(">=tablet") {
      width: 74px !important;
    }

    @include media("<=tablet") {
      left: 200px !important;
    }
  }
}
