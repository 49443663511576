.sidebar-opener {
  margin-top: 5px;
  margin-right: 13px;
  font-size: 14px;
  // border: 1px solid;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
}
