.info-popup {
  color: var(--gray-200);

  .info-opener {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: currentColor;

    &:hover {
      color: var(--primary);
    }
  }
}
