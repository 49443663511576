@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?9ern1e');
  src:  url('../../assets/fonts/icomoon.eot?9ern1e#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?9ern1e') format('truetype'),
    url('../../assets/fonts/icomoon.woff?9ern1e') format('woff'),
    url('../../assets/fonts/icomoon.svg?9ern1e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e906";
}
.icon-caret-down:before {
  content: "\e902";
}
.icon-menu:before {
  content: "\e900";
}
.icon-contacts:before {
  content: "\e901";
}
.icon-fileshare:before {
  content: "\e903";
}
.icon-info:before {
  content: "\e904";
}
.icon-question:before {
  content: "\e905";
}
.icon-admin:before {
  content: "\e907";
}
.icon-signature:before {
  content: "\e908";
}
.icon-inbox:before {
  content: "\e909";
}
.icon-plus:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e90b";
}
.icon-chevron-up:before {
  content: "\e91b";
}
.icon-cloud-upload:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-settings:before {
  content: "\e90e";
}
.icon-send:before {
  content: "\e90f";
}
