.box {
  &-title {
    padding: 2px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      margin-bottom: 0;
    }

    .drop {
      margin-left: 10px;
    }

    .icon {
      @include animate(color);
      font-size: 20px;
      color: var(--secondary);

      &:hover {
        color: var(--primary);
      }
    }

  }

  &-controls {
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      margin-left: auto;
      margin-bottom: 14px;
    }

    .checkbox-holder {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &.padding-sm {
      padding: 14px 0 6px;
    }

    &.padding-lg {
      padding: 12px 0;
    }
  }

  &-opener {
    color: currentColor;
    margin-bottom: 4px;

    &:hover {
      color: var(--primary);

      .icon {
        color: var(--primary);
      }
    }

    .icon {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 7px;
    }
  }
}
