// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// clearfix
%clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}

// transition
%transition {
	transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
	text-align: justify;
	font-size: 1px;
	line-height: 0px;
	> * {
		display: inline-block;
		vertical-align: top;
		text-align: left;
		font-size: $font-size-base;
		line-height: $line-height-base;
	}
	&:after {
		content: "";
		width: 100%;
		display: inline-block;
		vertical-align: top;
	}
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}

.error-snack {
	position: fixed;
	bottom: 100px;
	right: 22px;
	background-color: var(--primary);
	color: var(--light);
	opacity: 0.95;
	width: 25%;
	display: block;
	z-index: 1000;
	border: 1px solid transparent;
	border-radius: 5px;
	font-size: 14px;
	padding: 10px;
}

.pl-10{
	padding-left: 10px;
}

.snack-success {
	position: fixed;
	bottom: 50px;
	right: 22px;
	background-color: var(--secondary);
	color: var(--light);
	opacity: 0.99;
	width: 25%;
	display: block;
	z-index: 1000;
	border: 1px solid transparent;
	border-radius: 5px;
	font-size: 14px;
	padding: 10px;

	a {
		text-decoration: underline;
		color: var(--light);
		margin-left: 15px;
		margin-right: 5px;
		opacity: 1;
	}
}
