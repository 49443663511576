:root {
  --primary: #D93025;
  --primary-contrast: #FFFFFF;
  --primary-shade: #C32C21;
  --primary-shade-darker: #AD271D;
  --primary-shade-darkest: #98221A;
  --primary-tint: #DD4338;
  --primary-tint-lighter: #E0554B;
  --primary-tint-lightest: #D930252B;

  --secondary: #1A73E8;
  --secondary-contrast: #FFFFFF;
  --secondary-shade: #0D4792;
  --secondary-shade-dark: #1768D1;
  --secondary-shade-darker: #145CBA;
  --secondary-shade-darkest: #1251A2;
  --secondary-tint: #70A8F1;
  --secondary-tint-lighter: #69a6ce;
  --secondary-tint-lightest: #71b7e6;
  --tags-background: rgba(255, 255, 255, 0.8);

  --gray-100: #DEDEDE;
  --gray-200: #777777;
  --gray-250: #7E7A7A;
  --gray-300: #4D4D4D;
  --gray-400: #555555;

  --dark: #000;
  --dark-contrast: #ffffff;
  --dark-shade: #2D2D2D;
  --dark-tint: #2B2B2B;

  --light: #FFFFFF;
  --light-shade: #F1EFEF;

  --border: #DEDEDE;

  --shadow: #00000029;
  --background: #F1EEEE;
  --text: #FFFFFF;

  --success: #23C52D;
  --error: #C52D23;

  --try-free-button-color: #AD271D;
  --try-free-button-color-darkest: #98221A;
  --try-free-button-text-color: #FFFFFF;
}
