
$breakpoints: (
	"xs-phone": 320px,
	"phone": 480px,
	"tablet": 768px,
	"desktop": 1024px,
	"widescreen": 1200px,
	"hd": 1500px,
);

// keywords
$media-expressions: (
	"screen": "screen",
	"print": "print",
	"handheld": "handheld",
	"landscape": "(orientation: landscape)",
	"portrait": "(orientation: portrait)",
	"retina2x": "(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)",
	"retina3x": "(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)",
);

// ligatured operators ≥ ≤
@import "./default.scss";

@import "./base/modal";

@import "vendors/icomoon";
@import "vendors/include-media";
@import "vendors/normalize";

@import "./base/functions";
@import "./base/variables";
@import "./base/mixins";
@import "./base/helpers";
@import "./base/reset";
@import "./base/typography";
@import "./base/forms";

@import "layout/common";
@import "layout/header";
@import "layout/footer";
@import "layout/sidebar";

@import "components/btn";
@import "components/sidebar-opener";
@import "components/drop";
@import "components/search-bar";
@import "components/avatar";
@import "components/sidenav";
@import "components/box";
@import "components/info-popup";
@import "components/upload-area";
@import "components/attachment";
@import "components/loading";
@import "components/modal";
@import "components/checkbox";


// keep it as the legacy
/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
