.search-bar {
  max-width: 455px;
  width: 100%;
  font-family: $font-nunito;
  font-size: 18px;

  input {
    @include animate(background-color);
    border: 0;
    padding: 14px 38px;
    border-radius: $border-radius;
    background-color: var(--light);
    outline: none;

    &:focus {
      background-color: var(--light-darken-5);
    }

    @include placeholder {
      color: var(--secondary);
      font-weight: 300;
    }
  }

  .input-holder {
    position: relative;
  }

  .pre-icon,
  .post-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--secondary);
    pointer-events: none;
  }

  .pre-icon {
    font-size: 16px;
    left: 15px;
  }

  .post-icon {
    font-size: 6px;
    right: 19px;
  }
}
