.sidenav {

  .btn-holder {
    margin-bottom: 18.5px;
    padding: 0 14px;
  }

  &-list {
    @extend %listreset;
    font-family: $font-nunito;
    color: var(--dark-tint);
    font-weight: 600;

    > li {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-link {
    padding: 14px 10px;
    width: 100%;
    min-height: 48px;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    border-style: none;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: var(--light-shade);
      color: currentColor;
    }

    &:active {
      background-color: var(--light-shade);
      color: currentColor;
    }

    .icon {
      width: 32px;
      font-size: 17.5px;

      &-inbox {
        font-size: 14px;
      }

      &-fileshare {
        margin-left: -2px;
        margin-right: 2px;
      }
      &-signature {
        margin-left: 1px;
        margin-right: -1px;
      }
    }

    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 0.1rem;
      align-self: flex-end;
    }

    &.active {
      background-color: var(--light-shade);
      color: currentColor;
    }

    .sidebar-active & {
      @include media(">=tablet") {
        padding: 14px 12px !important;
        width: 60px !important;
        height: 60px !important;
        margin: 0 7px;
        border-radius: 50%;
        justify-content: center;

        .text {
          display: none;
        }

        .icon {
          width: auto;
        }
      }
    }
  }

  .sidebar-active & {
    @include media(">=tablet") {
      .btn-holder {
        padding: 0 7px;
      }

      .btn {
        min-height: 50px;
        width: 60px;
        min-width: auto;

        .icon {
          margin-right: 0;
        }

        .text {
          display: none;
        }
      }
    }
  }

}
