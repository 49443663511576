.footer-left {
  background-color: var(--light);
  width: 35%;
  height: var(--footer-height);
  padding-left: 70px;
  padding-right: 50px;
  position: absolute;
  float: center;
  align-self: center;
  bottom: 0;
  line-height: var(--footer-height);
  font-size: var(--footer-font-size);
  z-index: 100;

  @include media("<widescreen") {
    font-size: var(--footer-font-size-mobile);
  }

  @include media("<desktop") {
    padding: 0;
    width: 100%;
    float: none;
    display: block;
  }

  @include media("<tablet") {
    width: 100%;
    height: var(--footer-height-mobile);
    line-height: var(--footer-height-mobile);
    font-size: var(--footer-font-size-mobile);
  }

  > ul {
    @extend %listreset;
    display: flex;
    float: right;
    align-items: center;
    justify-content: flex-start;

    @include media("<desktop") {
      float: none;
      margin: 0;
      justify-content: center;
    }

    @include media("<tablet") {
      float: none;
      margin-left: 0px;
      margin-right: 0px;
    }

    @include media("<xs-phone") {
      float: none;
      margin-left: 20px;
    }

    > li {
      background-color: transparent;
      margin-left: 12px;
      margin-right: 12px;

      @include media("<tablet") {
        margin-left: 4px;
        margin-right: 4px;
      }

      @include media("<phone") {
        font-size: 10px;
      }

      @include media("<xs-phone") {
        float: none;
        font-size: 9px;
      }
    }
  }
}

.footer-right {
  display: block;
  position: fixed;
  margin-bottom: 0px;
  right: 100px;
  bottom: 0px;
  height: var(--footer-height);
  line-height: var(--footer-height);
  font-size: var(--footer-font-size);
  background-color: transparent;

  @include media(">desktop") {
    width: 35%;
  }

  @include media("<desktop") {
    position: relative;
  }

  @include media("<tablet") {
    position: relative;
    width: 100%;
    right: 0;
    height: var(--footer-height-mobile);
    line-height: var(--footer-height-mobile);
    font-size: var(--footer-font-size-mobile);
  }

  > ul {
    @extend %listreset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -10px;
    @include media(">=tablet") {
      margin: 0 10px;
    }
    @include media("<tablet") {
      justify-content: center;
      margin: 0;
    }

    > li {
      margin: 0 10px;
      @include media(">=tablet") {
        margin: 0 25px;
      }
      @include media("<phone") {
        font-size: 10px;
      }
    }
    .drop {
      margin-right: 15px;
    }
  }
}
