.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $btn-padding;
  color: $btn-color;
  background-color: $btn-background;
  font-size: $btn-font-size;
  font-family: $font-nunito;
  border-radius: $border-radius;
  text-align: center;
  border: 1px solid $btn-background;
  overflow: hidden;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: $btn-color;
    background-color: var(--primary-shade-darker);
    border-color: var(--primary-shade-darker);
  }

  &:focus {
    color: $btn-color;
    background-color: var(--primary-shade);
    border-color: var(--primary-shade);
  }

  &:active {
    color: $btn-color;
    background-color: var(--primary-shade-darkest);
    border-color: var(--primary-shade-darkest);
    box-shadow: none;
  }

  &.disabled,
  &[disabled] {
    color: $btn-color;
    background-color: $btn-background;
    border-color: $btn-background;
  }

  .icon {
    font-size: 16.5px;
    margin-right: 8px;
  }

  &-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-contrast);

    &:hover {
      color: $btn-color;
      background-color: var(--primary-shade-darker);
      border-color: var(--primary-shade-darker);
    }

    &:focus {
      color: $btn-color;
      background-color: var(--primary-shade);
      border-color: var(--primary-shade);
    }

    &:active {
      color: $btn-color;
      background-color: var(--primary-shade);
      border-color: var(--primary-shade);

      box-shadow: none;
    }

    &.disabled,
    &[disabled] {
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }

  &-info {
    // margin-top: 0.5rem;
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--secondary-contrast);

    &:hover {
      background-color: var(--secondary-shade-darker);
      border-color: var(--secondary-shade-darker);
      color: var(--secondary-contrast);
    }

    &:focus {
      background-color: var(--secondary-shade-dark);
      border-color: var(--secondary-shade-dark);
    }

    &:active {
      background-color: var(--secondary-shade-darkest);
      border-color: var(--secondary-shade-darkest);
    }

    &.disabled,
    &[disabled] {
      background-color: var(--secondary);
      border-color: var(--secondary);
    }
  }

  &-shadow {
    box-shadow: $box-shadow;
  }

  &-outline {
    box-shadow: none;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    background-color: transparent;

    &:hover {
      background-color: var(--secondary);
      border-color: var(--secondary);
    }

    &:focus {
      background-color: var(--secondary-shade-dark);
      border-color: var(--secondary-shade-dark);
    }

    &:active {
      background-color: var(--secondary-shade-darkest);
      border-color: var(--secondary-shade-darkest);
    }

    &.disabled,
    &[disabled] {
      border-color: var(--secondary);
      color: var(--secondary);
      background-color: transparent;
    }
  }

  &-md {
    padding: 10px 10px;
    font-size: 14px;

    .icon {
      font-size: 13px;
      margin-right: 6px;

      &-send {
        font-size: 16px;
        margin-right: 7px;
      }
    }
  }

  &.disabled,
  &[disabled] {
    opacity: 0.6;
  }

  &-cancel {
    background-color: var(--gray-200);
    border-color: var(--gray-200);
    color: var(--secondary-contrast);

    &:hover {
      color: $btn-color;
      background-color: var(--gray-300);
      border-color: var(--gray-300);
    }

    &:focus {
      color: $btn-color;
      background-color: var(--gray-300);
      border-color: var(--gray-300);
    }

    &:active {
      color: $btn-color;
      background-color: var(--gray-250);
      border-color: var(--gray-250);
      box-shadow: none;
    }

    &.disabled,
    &[disabled] {
      background-color: var(--gray-300);
      border-color: var(--gray-300);
    }
  }
}
