/* MODAL STYLES
-------------------------------*/
cs-modal {
    /* modals are hidden by default */
    display: none;

    .cs-modal {
        /* modal container fixed across whole screen */
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* z-index must be higher than .cs-modal-background */
        z-index: 900;

        /* enables scrolling for tall modals */
        overflow: auto;

        .cs-modal-body {
            @include animate(background-color);
            border-radius: 50px;
            padding: 20px;
            background: var(--light);

            /* margin exposes part of the modal background */
            @include media(">=tablet") {
                margin: 250px;
            }
            @include media("<tablet") {
                margin: 20px;
            }
        }
    }

    .cs-modal-background {
        /* modal background fixed across whole screen */
        @include animate(background-color);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* semi-transparent black  */
        background-color: var(--dark-tint);
        opacity: 0.75;

        /* z-index must be below .cs-modal and above everything else  */
        z-index: 800;
    }
}

body.cs-modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}
