html,
body {
  height: 100%;
}

body {
  &.sidebar-active {
    @include media("<tablet") {
      overflow: hidden;
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main-content {
  @include animate(padding);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  will-change: padding;

  @include media(">=tablet") {
    padding-left: var(--sidebar-width);
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    @include media(">=desktop") {
      padding: 0 25px 0 24px;
    }
  }

  .sidebar-active & {
    @include media(">=tablet") {
      padding-left: 74px;
    }
  }
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  min-height: 100%;
}

.lead {
  font-size: 15.5px;
  font-weight: 600;
}

.page-controls {
  margin-bottom: 14px;
  font-family: $font-nunito;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  margin-top: 4px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  margin-bottom: -30px;

  @include media(">=desktop") {
    flex-grow: 1;
  }
}

.col {
  margin-bottom: 30px;
  padding: 0 18px;
  width: 100%;

  @include media(">=desktop") {
    border-right: 2px solid var(--border);

    &:last-child {
      border-right: 0;
    }
  }

  &-sm {
    @include media(">=desktop") {
      width: 50%;
    }

    @include media(">=hd") {
      width: 26.3%;
    }
  }

  &-md {
    @include media(">=desktop") {
      width: 50%;
    }

    @include media(">=hd") {
      width: 36%;
      padding-left: 26px;
    }
  }
}

.border-top {
  border-top: 1px solid var(--border);
}

.border-right {
  border-right: 2px solid var(--border);
}

.border-bottom {
  border-bottom: 1px solid var(--border);
}

.border-left {
  border-left: 1px solid var(--border);
}

.label-group {
  margin-left: 10px;
  display: flex;
  align-items: center;

  .info-popup {
    margin-left: 7px;
  }
}

.logo {
  max-width: 118px;
  @include media("<tablet") {
    max-width: 100px;
  }

  a {
    display: block;
  }
}

.hide-on-mob {
  background-color: var(--light);
  @include media("<tablet") {
    display: none !important;
  }
}

.overlay {
  @include animate(opacity visibility);
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--secondary), 0.7);
  opacity: 0;
  visibility: hidden;

  .sidebar-active & {
    opacity: 1;
    visibility: visible;
  }

  span {
    padding: 5px;
    border-radius: 5px;
    background-color: var(--gray-200);
    position: relative;
    top: 50%;
  }

  .error {
    color: var(--primary);
  }

  .success {
    color: var(--secondary);
  }
}
