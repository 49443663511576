// forms
form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
//input[type='url'],
// input[type='date'],
textarea {
  @include animate(border-color);
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	box-sizing: border-box;
	border: 1px solid $form-element-border-color;
	padding: $form-element-padding;
  width: 100%;
  outline: none;

	// &:hover {

	// }

	&:focus {
		border-color: $form-element-focus-border-color;
	}

	@include placeholder {
		color: $placeholder-color;
	}
}

select {
	-webkit-border-radius: 0;
}

textarea {
	resize: vertical;
	vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	cursor: pointer;
}

.input-line-holder {
  position: relative;

  input {
    padding: 6px 0;
    border: 0;
    border-bottom: 1px solid var(--border);
    border-radius: 0;
  }

  &.with-info {
    input {
      padding-right: 25px;
    }

    .drop {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.checkbox-holder {
  input[type="checkbox"] {
    display: none;

    &:checked {
      + label {
        &::before {
          border-color: currentColor;
        }

        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  label {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 18px;
    font-size: 11.5px;

    &::before {
      @include animate(border-color);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      border: 1px solid var(--gray-100);
      border-radius: 2px;
    }

    &::after {
      @include animate(opacity visibility);
      content: "\e906";
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'icomoon' !important;
      opacity: 0;
      visibility: hidden;
      font-size: 10px;
    }
  }
}
