/* SET CHECKBOX COLOR FROM HOT PINK TO RED*/

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 !important;
    background-color: var(--primary-tint) !important;
  }


  ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,

  ::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--primary-tint);
  }

  ::ng-deep .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    border-color: var(--dark) !important;
    background-color: var(--dark) !important;
  }
